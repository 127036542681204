<template>
	<section class="activity-details col" align-x="left" :key="id">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item to="/manager/activity">最新活動</el-breadcrumb-item>
			<el-breadcrumb-item>{{_activity.title}}</el-breadcrumb-item>
		</el-breadcrumb>
		<br>
		<h1>{{_activity.title}}</h1>
		<h2>{{_activity.sub_title}}</h2>
		<hr>
		<template v-if="!isNew">
			<br>
			<p>上傳縮圖</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload-thumb v-model="thumb" />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ thumb })" type="success">儲存</el-button>
						<el-button @click="handleCancel('thumb')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
			<br>
			<br>
			<p>上傳 Banner</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload v-model="banners" multiple />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ banners })" type="success">儲存</el-button>
						<el-button @click="handleCancel('banners')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
			<br>
			<p>上傳 Banner 手機版</p>
			<el-card>
				<div class="row" gutter="10">
					<div>
						<upload v-model="banners_mobile" multiple />
					</div>
					<div class="row" align-self="bottom">
						<el-button @click="handleSave({ banners_mobile })" type="success">儲存</el-button>
						<el-button @click="handleCancel('banners_mobile')" type="info">取消</el-button>
					</div>
				</div>
			</el-card>
		</template>
		<br>
		<br>
		<el-card>
			<el-form :model="$data">
				<el-form-item prop="title" label="標題">
					<el-input v-model="title" />
				</el-form-item>
				<el-form-item prop="sub_title" label="小標題">
					<el-input v-model="sub_title" />
				</el-form-item>
				<!-- <el-form-item prop="youtube" label="Youtube 網址">
					<el-input v-model="youtube" />
				</el-form-item>
				<el-form-item v-if="youtubeId" label="預覽">
					<div class="youtube">
						<iframe :src="`https://www.youtube.com/embed/${youtubeId}`" frameborder="0" allowfullscreen />
					</div>
				</el-form-item> -->
				<el-form-item prop="perform_dates" label="演出時間">
					<el-date-picker v-model="perform_dates" value-format="timestamp" type="daterange" range-separator="至" />
				</el-form-item>
				<el-form-item prop="location" label="演出地點">
					<el-input v-model="location" />
				</el-form-item>
				<el-form-item prop="price" label="票價">
					<el-input v-model="price" placeholder="NT$" />
				</el-form-item>
				<el-form-item prop="link" label="購票連結">
					<el-input v-model="link" />
				</el-form-item>
				<el-form-item>
					<div class="row" align-x="right">
						<el-button @click="handleSave({ title, sub_title, youtube, perform_dates, location, price, link })" type="success">儲存</el-button>
						<el-button @click="handleCancel(['title', 'sub_title', 'youtube', 'perform_dates', 'location', 'price', 'link'])" type="info">取消</el-button>
					</div>
				</el-form-item>
			</el-form>
		</el-card>
		<br>
		<br>
		<p>編輯內容</p>
		<el-card>
			<tinymce v-model="details" />
			<div class="center">
				<el-button @click="handleSave({ details })" type="success">儲存</el-button>
				<el-button @click="handleCancel('details')" type="info">取消</el-button>
			</div>
		</el-card>
		<template v-if="id && id != 'new'">
			<br>
			<br>
			<el-button @click="handleRemove()" type="danger" align-self="center">刪除此項活動</el-button>
		</template>
		<br>
	</section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import querystring from "querystring";
export default {
	components: {
		UploadThumb: () => import("@/components/upload-thumb"),
		Upload: () => import("@/components/upload"),
		Tinymce: () => import("@/components/tinymce"),
	},
	props: ["id"],
	data() {
		return {
			title: "",
			sub_title: "",
			thumb: "",
			banners: [],
			banners_mobile: [],
			youtube: "",
			perform_dates: [],
			location: "",
			price: "",
			link: "",
			details: "",
		}
	},
	computed: {
		...mapState("activity", ["_activity"]),
		isNew() {
			return this.id == "new";
		},
		youtubeId() {
			const { youtube } = this._activity;
			return this.youtube && querystring.parse(new URL(this.youtube).searchParams.toString())["v"] || ""
		}
	},
	methods: {
		...mapActions("activity", ["_getData", "_updateData", "_createData", "_removeData"]),
		async handleSave(data) {
			if (this.isNew) {
				const { id, message } = await this._createData(data);
				this.$message.success({ message });
				this.$router.push(`/manager/activity/${id}`);
			}
			else this.$message.success(await this._updateData({
				id: this.id,
				data
			}));
		},
		handleCancel(key) {
			if (key.forEach) {
				key.forEach(k => {
					this.$set(this.$data, k, this._.cloneDeep(this._activity[k]));
				});
			} else {
				this.$set(this.$data, key, this._.cloneDeep(this._activity[key]));
			}
		},
		handleRemove() {
			this.$confirm(`確認要刪除「${this._activity.title || "尚未命名"}」？`, { type: "warning" })
				.then(async () => {
					this.$message.success(await this._removeData(this.id));
					this.$router.push("/manager/activity");
				});
		}
	},
	async created() {
		if (!this.isNew) {
			await this._getData(this.id);
			this._.assign(this.$data, this._.cloneDeep(this._activity));
		}
	}
}
</script>